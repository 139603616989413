@import "../../assets/css/common.scss";

.footer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  background: #ececec;
  border-radius: 50px 50px 0px 0px;

  .install-app {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background: #5d4fff;
    border-radius: 100px;
    margin: 27px;

    span {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 40px;
      display: flex;
      align-items: center;
      color: #ffffff;
      margin-right: 50px;
    }

    .btn-wrap {
      display: flex;
      flex-direction: row;

      img {
        width: 148px;
        margin-right: 27px;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
          transform: scale(0.95);
        }
      }
    }
  }

  .left-info {
    padding: 25px 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 100px;

    .logo-wrap {
      display: flex;
      align-items: center;

      img {
        z-index: 10;
        height: 23px;
        margin-right: 50px;
      }

      .logo {
        height: 40px;
        margin-right: 5px;
      }

      .icon {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        background: #2b303a;
        border-radius: 9px;
        margin-right: 19px;
        transition: all 0.3s ease;
        cursor: pointer;

        &:hover {
          background: #d96739;
          transform: scale(1.2);
        }
      }
    }

    .info-wrap {
      display: flex;
      flex: 1;
      flex-direction: row;
      justify-content: space-between;

      .social {
        display: flex;
        flex-direction: column;
        margin-top: 25px;
        margin-right: 50px;

        .title {
          color: #000;
          font-size: 33px;
          font-weight: 700;
          margin-bottom: 29px;
        }

        .social-row {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 25px;
          cursor: pointer;
          user-select: none;
          transition: all 0.3s ease;

          &:hover {
            color: #d96739;

            .icon {
              background: #d96739;
              transform: scale(1.2);
            }
          }

          .icon {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            background: #2b303a;
            border-radius: 9px;
            margin-right: 19px;
            transition: all 0.3s ease;
          }

          img {
            width: 20px;
          }
        }
      }
    }
  }

  .backToTop {
    width: 200px;
    border: 1px solid #2b303a;
    border-radius: 5px;
    padding: 11px 21px;
    cursor: pointer;
    user-select: none;
    transition: all 0.3s ease;
    &:hover {
      transform: scale(0.95);
    }
    margin: -70px 110px 30px 0;
    align-self: flex-end;

    span {
      font-family: "Montserrat";
      font-style: italic;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      margin-right: 12px;
    }
  }

  .copyright {
    background: #ececec;
    border-top: 1px solid #d3d9e6;
    padding: 24px 174px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;

    span {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: right;
      color: #1b2a4a;
    }
  }
}

@media screen and (max-width: 1200px) {
  .footer {
    border-radius: 40px 40px 0px 0px;

    .install-app {
      padding: 10px;
      margin: 20px;

      span {
        font-size: 16x;
        line-height: 30px;
        margin-right: 40px;
      }

      .btn-wrap {
        img {
          width: 120px;
          margin-right: 20px;
        }
      }
    }

    .left-info {
      padding: 25px 50px;

      .logo-wrap {
        img {
          z-index: 10;
          height: 20px;
        }

        .logo {
          height: 35px;
          margin-right: 5px;
        }
      }

      .info-wrap {
        .social {
          margin-top: 20px;
          margin-right: 40px;

          .title {
            font-size: 28px;
            margin-bottom: 20px;
          }

          .social-row {
            margin-bottom: 20px;

            .icon {
              width: 36px;
              height: 36px;
              border-radius: 9px;
              margin-right: 15px;
            }

            img {
              width: 16px;
            }
          }
        }
      }
    }

    .backToTop {
      width: 150px;
      border-radius: 5px;
      padding: 11px 21px;
      margin: -70px 110px 30px 0;

      span {
        font-size: 20px;
        line-height: 29px;
        margin-right: 12px;
      }
    }

    .copyright {
      padding: 20px 130px;
      margin-bottom: 10px;

      span {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .footer {
    border-radius: 30px 30px 0px 0px;

    .install-app {
      padding: 5px;
      margin: 20px;

      span {
        font-size: 12px;
        line-height: 25px;
        margin-right: 30px;
      }

      .btn-wrap {
        img {
          width: 100px;
          margin-right: 15px;
        }
      }
    }

    .left-info {
      padding: 20px 40px;

      .logo-wrap {
        img {
          z-index: 10;
          height: 20px;
        }

        .logo {
          height: 35px;
          margin-right: 5px;
        }
      }

      .info-wrap {
        .social {
          margin-top: 15px;
          margin-right: 30px;

          .title {
            font-size: 20px;
            margin-bottom: 15px;
          }

          .social-row {
            margin-bottom: 15px;

            .icon {
              width: 30px;
              height: 30px;
              border-radius: 5px;
              margin-right: 10px;
            }

            span {
              font-size: 12px;
            }

            img {
              width: 10px;
            }
          }
        }
      }
    }

    .backToTop {
      width: 120px;
      border-radius: 5px;
      padding: 11px 10px;
      margin: -70px 110px 30px 0;

      span {
        font-size: 14px;
        line-height: 20px;
        margin-right: 12px;
      }
    }

    .copyright {
      padding: 20px 130px;
      margin-bottom: 10px;

      span {
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .footer {
    border-radius: 20px 20px 0px 0px;

    .install-app {
      padding: 10px 20px;
      margin: 15px;

      span {
        font-size: 10px;
        line-height: 14px;
        margin-right: 20px;
      }

      .btn-wrap {
        img {
          width: 70px;
          margin-right: 10px;
        }
      }
    }

    .left-info {
      padding: 20px 30px;

      .logo-wrap {
        img {
          z-index: 10;
          height: 15px;
        }

        .logo {
          height: 25px;
          margin-right: 5px;
        }
      }

      .info-wrap {
        .social {
          margin-top: 20px;
          margin-right: 20px;

          .title {
            font-size: 14px;
            margin-bottom: 15px;
          }

          .social-row {
            margin-bottom: 15px;

            .icon {
              width: 20px;
              height: 20px;
              border-radius: 5px;
              margin-right: 10px;
              padding: 2px;
            }

            span {
              font-size: 10px;
            }

            img {
              width: 10px;
            }
          }
        }
      }
    }

    .backToTop {
      width: 100px;
      border-radius: 5px;
      padding: 5px 10px;
      margin: -70px 30px 30px 0;

      span {
        font-size: 12px;
        line-height: 20px;
        margin-right: 12px;
      }

      img {
        width: 10px;
      }
    }

    .copyright {
      padding: 20px 30px;
      margin-bottom: 0px;

      span {
        font-size: 10px;
        line-height: 10px;
      }
    }
  }
}
