@import "../../assets/css/common.scss";

.homepage {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  overflow: hidden;
  position: relative;

  &.affiliate {
    height: unset;
    overflow: visible;
  }

  .home-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #041539;

    .top-linear {
      height: 850px;
      background: linear-gradient(180deg, #d96739 0%, #041539 29.17%);
    }

    div {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  .nav-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 65px 130px;
    padding: 30px;
    position: relative;
    box-shadow: 0px 4px 11px 0px #0000002e;
    border-radius: 13px;
    background-color: #fff;
    z-index: 999;

    .logo-wrap {
      display: flex;
      align-items: center;

      img {
        z-index: 10;
        height: 23px;
      }

      .logo {
        height: 40px;
        margin-right: 12px;
      }
    }

    .nav-group {
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-size: 16px;
        font-weight: 700;
        color: #1b2a4a;
        opacity: 0.7;
        margin-right: 70px;
        transition: all 0.3s ease;

        &:active,
        &:hover {
          color: #d96739;
        }
      }
    }
  }

  .home-content-wrap {
    display: flex;
    flex: 1;
    flex-direction: row;
    z-index: 10;
    padding: 0 130px;
    // padding-top: 30px;

    .left-content {
      display: flex;
      flex-direction: column;
      padding-top: 40px;
      padding-right: 150px;
      transition: all 0.3s ease;
      z-index: 999;

      .title {
        font-weight: 700;
        font-size: 64px;
        line-height: 78px;
        color: #ffffff;
        width: 800px;
      }
      .detail {
        font-style: italic;
        font-weight: 600;
        font-size: 24px;
        line-height: 25px;
        color: #ffffff;
        opacity: 0.8;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        margin-top: 20px;
        width: 550px;
      }

      .btn-wrap {
        display: flex;
        flex-direction: row;
        margin-top: 35px;

        img {
          width: 192px;
          margin-right: 27px;
          cursor: pointer;
          transition: all 0.3s ease;

          &:hover {
            transform: scale(0.95);
          }
        }
      }
    }

    .img-ls {
      position: absolute;
      right: 0;
      bottom: 0;
      // padding: 45px;
      display: flex;
      height: fit-content;
      flex: 1;

      img {
        width: 90%;
        z-index: 3;
      }

      .ball {
        position: absolute;
        top: 400px;
        right: 650px;
        width: 103px;
        height: 103px;
        border-radius: 103px;
        background: linear-gradient(
          180deg,
          #d96739 0%,
          rgba(217, 103, 57, 0) 59.9%
        );
        transform: rotate(-107.64deg);
        opacity: 0.7;
        z-index: 1;
      }

      .ball1 {
        position: absolute;
        top: 100px;
        right: 300px;
        width: 307px;
        height: 307px;
        border-radius: 307px;
        opacity: 0.7;
        z-index: 1;
        background: linear-gradient(
          180deg,
          #d96739 0%,
          rgba(217, 103, 57, 0) 59.9%
        );
      }

      .ball2 {
        position: absolute;
        top: 160px;
        right: 110px;
        width: 71.22px;
        height: 71.22px;
        border-radius: 71.22px;
        opacity: 0.7;
        z-index: 1;
        background: linear-gradient(
          180deg,
          #d96739 0%,
          rgba(217, 103, 57, 0) 59.9%
        );
        transform: rotate(108.84deg);
      }
    }
  }

  .affiliate-content-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .header-view {
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 999;

      .hello {
        width: 200px;
        position: relative;
        margin-left: -50px;

        .msg {
          width: 240px;
          z-index: 99;
          position: relative;
        }

        .logo {
          width: 78px;
          position: absolute;
          top: -30px;
          right: 0px;
        }
      }

      .tille-wrap {
        position: relative;
        span {
          color: #2b303a;
          font-size: 40px;
          font-weight: 700;

          .line-through {
            border-radius: 15px;
            color: white;
            background: #2b303a;
            padding: 5px 10px;
          }
        }

        img {
          width: 600px;
          position: absolute;
          top: 35px;
          left: 300px;
        }
      }
    }

    .main {
      width: 700px;
      margin-top: 50px;
      position: relative;

      img {
        width: 100%;
      }

      .left-detail {
        position: absolute;
        left: -140px;
        bottom: 0;
        display: flex;
        flex-direction: column;

        .title-row {
          display: flex;
          flex-direction: row;
          align-items: center;

          span {
            font-weight: 700;
            font-size: 24px;
            color: #373e44;
          }

          img {
            width: 190px;
          }
        }

        .detail {
          font-size: 16px;
          color: #373e44;
          width: 200px;
          margin-top: 20px;
        }

        .detail2 {
          font-size: 16px;
          color: #373e44;
          width: 200px;
          margin-top: 10px;
        }
      }

      .btn {
        background-color: #d96739;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 21px;
        box-shadow: 2px 4px 8px 0px #00000040;
        width: 247px;
        height: 69px;
        position: absolute;
        right: 0;
        bottom: 10px;
        cursor: pointer;
        user-select: none;

        span {
          font-size: 24px;
          font-weight: 600;
          color: #fff;
        }

        img {
          width: 24px;
          height: 24px;
          margin-left: 5px;
        }

        &:hover {
          box-shadow: none;
        }
      }

      .stamp {
        position: absolute;
        bottom: 20px;
        right: -120px;
        width: 120px;
        opacity: 0.6000000238418579;
        filter: grayscale(1.2);
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .homepage {
    .nav-wrap {
      margin: 40px 70px;
      padding: 20px;
      border-radius: 10px;

      .logo-wrap {
        display: flex;
        align-items: center;

        img {
          height: 23px;
        }

        .logo {
          height: 40px;
          margin-right: 12px;
        }
      }

      .nav-group {
        span {
          font-size: 14px;
          margin-right: 50px;
        }
      }
    }

    .home-content-wrap {
      padding: 0 70px;

      .left-content {
        padding-top: 0px;
        padding-right: 60px;

        .title {
          font-size: 40px;
          line-height: 56px;
          width: 450px;
        }
        .detail {
          font-size: 18px;
          line-height: 20px;
          margin-top: 15px;
          width: 400px;
        }

        .btn-wrap {
          margin-top: 35px;

          img {
            width: 140px;
            margin-right: 27px;
          }
        }
      }

      .img-ls {
        img {
        }

        .ball {
        }

        .ball1 {
        }

        .ball2 {
        }
      }
    }

    .affiliate-content-wrap {
      .header-view {
        .hello {
          width: 150px;
          margin-left: -40px;

          .msg {
            width: 190px;
            z-index: 99;
            position: relative;
          }

          .logo {
            width: 65px;
            top: -25px;
            right: 0px;
          }
        }

        .tille-wrap {
          span {
            font-size: 30px;

            .line-through {
              border-radius: 15px;
              padding: 5px 10px;
            }
          }

          img {
            width: 400px;
            top: 35px;
            left: 230px;
          }
        }
      }

      .main {
        width: 550px;
        margin-top: 50px;

        .left-detail {
          left: -115px;
          bottom: 0;

          .title-row {
            span {
              font-size: 20px;
            }

            img {
              width: 150px;
            }
          }

          .detail {
            font-size: 12px;
            width: 200px;
            margin-top: 15px;
          }

          .detail2 {
            font-size: 12px;
            width: 200px;
            margin-top: 8px;
          }
        }

        .btn {
          border-radius: 12px;
          box-shadow: 2px 4px 8px 0px #00000040;
          width: 180px;
          height: 50px;
          right: 0;
          bottom: 10px;

          span {
            font-size: 18px;
          }

          img {
            width: 20px;
            height: 20px;
            margin-left: 5px;
          }
        }

        .stamp {
          bottom: 20px;
          right: -100px;
          width: 100px;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .homepage {
    .nav-wrap {
      margin: 30px 40px;
      padding: 20px;
      border-radius: 10px;

      .logo-wrap {
        img {
          height: 20px;
        }

        .logo {
          height: 30px;
          margin-right: 10px;
        }
      }

      .nav-group {
        span {
          font-size: 12px;
          margin-right: 30px;
        }
      }
    }

    .home-content-wrap {
      flex-direction: column;
      padding: 0 40px;

      .left-content {
        justify-content: flex-end;
        padding-bottom: 130px;

        .title {
          font-size: 35px;
          line-height: 45px;
        }
        .detail {
          font-size: 15px;
          line-height: 20px;
          margin-top: 15px;
        }

        .btn-wrap {
          img {
          }
        }
      }

      .img-ls {
        img {
          width: 500px;
        }

        .ball {
          top: 190px;
          left: 0px;
        }

        .ball1 {
          top: 30px;
          left: 100px;
        }

        .ball2 {
          top: 120px;
          left: 420px;
        }
      }
    }

    .affiliate-content-wrap {
      .header-view {
        .hello {
          width: 100px;
          margin-left: -40px;

          .msg {
            width: 150px;
          }

          .logo {
            width: 50px;
            top: -20px;
            right: 0px;
          }
        }

        .tille-wrap {
          span {
            font-size: 24px;

            .line-through {
              border-radius: 10px;
              padding: 5px 10px;
            }
          }

          img {
            width: 300px;
            top: 35px;
            left: 180px;
          }
        }
      }

      .main {
        width: 430px;
        margin-top: 50px;

        .left-detail {
          left: -70px;
          bottom: 0;

          .title-row {
            span {
              font-size: 16px;
            }

            img {
              width: 90px;
            }
          }

          .detail {
            font-size: 10px;
            width: 150px;
            margin-top: 15px;
          }

          .detail2 {
            font-size: 10px;
            width: 150px;
            margin-top: 8px;
          }
        }

        .btn {
          border-radius: 10px;
          box-shadow: 2px 4px 8px 0px #00000040;
          width: 150px;
          height: 40px;
          right: 0;
          bottom: 10px;

          span {
            font-size: 14px;
          }

          img {
            width: 16px;
            height: 16px;
            margin-left: 5px;
          }
        }

        .stamp {
          bottom: 20px;
          right: -90px;
          width: 100px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .homepage {
    .nav-wrap {
      margin: 20px 20px;
      padding: 15px;
      border-radius: 10px;

      .logo-wrap {
        img {
          height: 12px;
        }

        .logo {
          height: 17px;
          margin-right: 5px;
        }
      }

      .nav-group {
        span {
          font-size: 8px;
          margin-right: 10px;
        }
      }
    }

    .home-content-wrap {
      padding: 0 20px;

      .left-content {
        padding-bottom: 130px;

        .title {
          font-size: 28px;
          line-height: 30px;
          width: 300px;
        }
        .detail {
          font-size: 13px;
          line-height: 18px;
          margin-top: 10px;
          width: 320px;
        }

        .btn-wrap {
          img {
            width: 120px;
          }
        }
      }

      .img-ls {
        img {
          width: 300px;
        }

        .ball {
          top: 190px;
          left: 20px;
          width: 50px;
          height: 50px;
        }

        .ball1 {
          top: 30px;
          left: 100px;
          width: 100px;
          height: 100px;
        }

        .ball2 {
          top: 120px;
          left: 250px;
          width: 50px;
          height: 50px;
        }
      }
    }

    .affiliate-content-wrap {
      padding-bottom: 40px;
      .header-view {
        .hello {
          width: 70px;
          margin-left: -40px;

          .msg {
            width: 120px;
          }

          .logo {
            width: 40px;
            top: -15px;
            right: -10px;
          }
        }

        .tille-wrap {
          span {
            font-size: 16px;

            .line-through {
              border-radius: 10px;
              padding: 5px 10px;
            }
          }

          img {
            width: 160px;
            top: 20px;
            left: 120px;
          }
        }
      }

      .main {
        width: 200px;
        margin-top: 30px;

        .left-detail {
          left: -60px;
          bottom: -40px;

          .title-row {
            span {
              font-size: 12px;
            }

            img {
              width: 50px;
            }
          }

          .detail {
            font-size: 8px;
            width: 100px;
            margin-top: 5px;
          }

          .detail2 {
            font-size: 8px;
            width: 100px;
            margin-top: 5px;
          }
        }

        .btn {
          border-radius: 7px;
          box-shadow: 1px 2px 8px 0px #00000040;
          width: 70px;
          height: 20px;
          right: 0;
          bottom: 5px;

          span {
            font-size: 7px;
          }

          img {
            width: 8px;
            height: 8px;
            margin-left: 3px;
          }
        }

        .stamp {
          bottom: 10px;
          right: -60px;
          width: 70px;
        }
      }
    }
  }
}
