@import "../../assets/css/common.scss";

.randomReview {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 170px 85px;
  align-items: flex-end;
  justify-content: center;
  background-color: #fff;
  overflow: hidden;

  .content {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    width: 30%;
    padding: 0 50px;
    padding-bottom: 350px;
    position: relative;

    .title {
      color: #000;
      font-size: 45px;
      font-weight: 600;
      line-height: 120%;
    }
    .detail {
      color: #000;
      font-size: 14px;
      font-weight: 500;
      margin-top: 15px;
    }

    .review3 {
      position: absolute;
      bottom: 200px;
      width: 300px;
      z-index: 10;
    }

    .line-right {
      position: absolute;
      bottom: 220px;
      left: 10px;
      right: -5px;
      height: 2px;
      background-color: #000000;
      z-index: 2;
    }

    .point-right {
      position: absolute;
      bottom: 216px;
      right: -10px;
      background-color: #000000;
      z-index: 2;
      width: 10px;
      height: 10px;
      border-radius: 10px;
    }

    .line-left {
      position: absolute;
      bottom: 220px;
      left: -200px;
      right: 200px;
      height: 2px;
      background-color: #000000;
      z-index: 6;
    }

    .line-left2 {
      position: absolute;
      bottom: 220px;
      top: calc(100% - 530px);
      left: -200px;
      width: 2px;
      background-color: #000000;
      z-index: 6;
    }

    .point-left {
      position: absolute;
      bottom: 220px;
      top: calc(100% - 535px);
      left: -204px;
      background-color: #000000;
      z-index: 6;
      width: 10px;
      height: 10px;
      border-radius: 10px;
    }
  }

  .review1 {
    position: relative;

    img {
      position: relative;
      width: 344px;
      z-index: 5;
    }

    .ball {
      width: 213px;
      height: 213px;
      transform: rotate(-20.442deg);
      flex-shrink: 0;
      border-radius: 213px;
      background: var(
        --gradient-or,
        linear-gradient(
          180deg,
          rgba(217, 103, 57, 0.46) 0%,
          rgba(217, 103, 57, 0) 59.91%
        )
      );
      position: absolute;
      top: -80px;
      left: 40px;
    }

    .ball1 {
      width: 103px;
      height: 103px;
      transform: rotate(-76.556deg);
      flex-shrink: 0;
      border-radius: 103px;
      background: var(
        --gradient-or,
        linear-gradient(
          180deg,
          rgba(217, 103, 57, 0.46) 0%,
          rgba(217, 103, 57, 0) 59.91%
        )
      );
      position: absolute;
      top: 160px;
      left: -50px;
    }

    .ball2 {
      width: 71.224px;
      height: 71.224px;
      transform: rotate(76.545deg);
      flex-shrink: 0;
      border-radius: 71.224px;
      background: var(
        --gradient-or,
        linear-gradient(
          180deg,
          rgba(217, 103, 57, 0.46) 0%,
          rgba(217, 103, 57, 0) 59.91%
        )
      );
      position: absolute;
      top: 80px;
      left: 320px;
    }
  }

  .review2-wrap {
    position: relative;

    .review2 {
      width: 278px;
    }

    .note {
      position: absolute;
      bottom: 0;
      right: 300px;
      width: 250px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      span {
        color: #000;
        text-align: right;
        font-size: 24px;
        font-weight: 600;
        margin-right: 50px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .randomReview {
    padding: 130px 50px;

    .content {
      width: 30%;
      padding: 0 50px;
      padding-bottom: 250px;

      .title {
        font-size: 28px;
        line-height: 120%;
      }
      .detail {
        font-size: 12px;
        margin-top: 15px;
      }

      .review3 {
        bottom: 170px;
        width: 200px;
      }

      .line-right {
        bottom: 183px;
        left: 10px;
        right: -5px;
      }

      .point-right {
        bottom: 179px;
        right: -10px;
      }

      .line-left {
        bottom: 183px;
        left: -130px;
        right: 200px;
      }

      .line-left2 {
        bottom: 183px;
        top: calc(100% - 400px);
        left: -130px;
        width: 2px;
      }

      .point-left {
        bottom: 183px;
        top: calc(100% - 405px);
        left: -134px;
      }
    }

    .review1 {
      img {
        width: 260px;
      }

      .ball {
        top: -80px;
        left: 20px;
      }

      .ball1 {
        top: 160px;
        left: -60px;
      }

      .ball2 {
        top: 80px;
        left: 240px;
      }
    }

    .review2-wrap {
      .review2 {
        width: 200px;
      }

      .note {
        bottom: 0;
        right: 210px;
        width: 220px;

        span {
          font-size: 20px;
          margin-right: 50px;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .randomReview {
    padding: 100px 30px;

    .content {
      width: 30%;
      padding: 0 30px;
      padding-bottom: 150px;

      .title {
        font-size: 20px;
        line-height: 120%;
      }
      .detail {
        font-size: 10px;
        margin-top: 15px;
      }

      .review3 {
        bottom: 100px;
        width: 150px;
      }

      .line-right {
        bottom: 110px;
        left: 10px;
        right: -5px;
      }

      .point-right {
        bottom: 105px;
        right: -10px;
      }

      .line-left {
        bottom: 110px;
        left: -90px;
        right: 200px;
      }

      .line-left2 {
        bottom: 110px;
        top: calc(100% - 265px);
        left: -90px;
        width: 2px;
      }

      .point-left {
        bottom: 110px;
        top: calc(100% - 270px);
        left: -94px;
      }
    }

    .review1 {
      img {
        width: 170px;
      }

      .ball {
        top: -50px;
        left: 20px;
        width: 150px;
      }

      .ball1 {
        top: 120px;
        left: -40px;
        width: 90px;
      }

      .ball2 {
        top: 50px;
        left: 140px;
        width: 40px;
      }
    }

    .review2-wrap {
      .review2 {
        width: 130px;
      }

      .note {
        bottom: 0;
        right: 140px;
        width: 150px;
        img {
          width: 50px;
        }

        span {
          font-size: 15px;
          margin-right: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .randomReview {
    padding: 70px 20px;

    .content {
      width: 100%;
      padding: 0 10px;
      padding-bottom: 150px;

      .title {
        font-size: 20px;
        line-height: 120%;
      }
      .detail {
        font-size: 10px;
        margin-top: 15px;
      }

      .review3 {
        bottom: 60px;
        width: 100px;
      }

      .line-right {
        bottom: 65px;
        left: 10px;
        right: -5px;
      }

      .point-right {
        bottom: 62px;
        right: -4px;
        width: 7px;
        height: 7px;
      }

      .line-left {
        bottom: 65px;
        left: -70px;
        right: 0px;
      }

      .line-left2 {
        bottom: 65px;
        top: calc(100% - 160px);
        left: -70px;
        width: 2px;
      }

      .point-left {
        bottom: 88px;
        top: calc(100% - 160px);
        left: -73px;
        width: 7px;
        height: 7px;
      }
    }

    .review1 {
      img {
        width: 100px;
      }

      .ball {
        top: -40px;
        left: 10px;
        width: 80px;
      }

      .ball1 {
        top: 70px;
        left: -30px;
        width: 70px;
      }

      .ball2 {
        top: 50px;
        left: 80px;
        width: 40px;
      }
    }

    .review2-wrap {
      .review2 {
        width: 70px;
      }

      .note {
        bottom: -30px;
        right: 80px;
        width: 100px;
        img {
          width: 40px;
        }

        span {
          font-size: 10px;
          margin-right: 20px;
        }
      }
    }
  }
}
