@import "../../../assets/css/common.scss";

.AffiliateBenefit {
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  overflow: hidden;
  padding: 100px 150px;

  .left-content {
    display: flex;
    flex-direction: column;
    margin-top: 40px;

    .extra {
      display: flex;
      align-items: center;
      align-self: flex-start;
      background-color: #f1e99b;
      padding: 10px 17px;
      border-radius: 44px;

      span {
        font-size: 16px;
        font-weight: 600;
        color: #797229;
        margin-left: 5px;
      }
    }

    .title1 {
      color: #5d4fff;
      font-size: 40px;
      font-weight: 700;
      margin-top: 31px;
    }

    .title2 {
      color: #2b303a;
      font-size: 40px;
      font-weight: 700;
    }

    .line {
      width: 40px;
      position: absolute;
      top: 300px;
      left: 360px;
    }

    .number-percent {
      border-radius: 13px;
      border: 2px dashed #000;
      background: #e0ddff;
      padding: 10px;
      align-self: flex-start;
      margin-top: 17px;

      span {
        color: var(--purple, #5d4fff);
        font-size: 40px;
        font-weight: 700;
      }
    }

    .detail {
      color: #000;
      font-size: 16px;
      font-weight: 500;
      width: 270px;
      margin-top: 31px;
    }
  }

  .right-content {
    flex: 1;
    img {
      min-width: 710px;
      width: 100%;
    }
  }
}

@media screen and (max-width: 1400px) {
  .AffiliateBenefit {
    padding: 70px 80px;

    .left-content {
      margin-top: 40px;

      .extra {
        padding: 10px 17px;
        border-radius: 40px;
        width: 260px;

        img {
          width: 20px;
        }

        span {
          font-size: 14px;
          margin-left: 5px;
        }
      }

      .title1 {
        font-size: 32px;
        font-weight: 700;
        margin-top: 26px;
      }

      .title2 {
        font-size: 32px;
      }

      .line {
        width: 30px;
        top: 250px;
        left: 260px;
      }

      .number-percent {
        border-radius: 13px;
        border: 2px dashed #000;
        padding: 7px;
        margin-top: 13px;

        span {
          color: var(--purple, #5d4fff);
          font-size: 36px;
        }
      }

      .detail {
        font-size: 14px;
        width: 270px;
        margin-top: 25px;
      }
    }

    .right-content {
      flex: 1;
      img {
        min-width: 500px;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .AffiliateBenefit {
    padding: 50px 30px;

    .left-content {
      margin-top: 20px;

      .extra {
        padding: 10px 17px;
        border-radius: 40px;
        width: 220px;

        img {
          width: 15px;
        }

        span {
          font-size: 12px;
          margin-left: 5px;
        }
      }

      .title1 {
        font-size: 26px;
        margin-top: 20px;
      }

      .title2 {
        font-size: 26px;
      }

      .line {
        width: 24px;
        top: 180px;
        left: 180px;
      }

      .number-percent {
        border-radius: 13px;
        border: 2px dashed #000;
        padding: 7px;
        margin-top: 13px;

        span {
          color: var(--purple, #5d4fff);
          font-size: 30px;
        }
      }

      .detail {
        font-size: 12px;
        width: 200px;
        margin-top: 20px;
      }
    }

    .right-content {
      flex: 1;
      img {
        min-width: 300px;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .AffiliateBenefit {
    padding: 50px 30px;
    flex-direction: column;

    .left-content {
      margin-top: 0px;

      .extra {
        padding: 10px 17px;
        border-radius: 40px;
        width: 220px;

        img {
          width: 15px;
        }

        span {
          font-size: 12px;
          margin-left: 5px;
        }
      }

      .title1 {
        font-size: 26px;
        margin-top: 20px;
      }

      .title2 {
        font-size: 26px;
      }

      .line {
        width: 24px;
        top: 160px;
        left: 180px;
      }

      .number-percent {
        border-radius: 13px;
        border: 2px dashed #000;
        padding: 7px;
        margin-top: 13px;

        span {
          color: var(--purple, #5d4fff);
          font-size: 30px;
        }
      }

      .detail {
        font-size: 12px;
        width: 80%;
        margin-top: 20px;
      }
    }

    .right-content {
      flex: 1;
      margin-top: 20px;
      img {
        min-width: 270px;
        width: 100%;
      }
    }
  }
}
