@import "../../assets/css/common.scss";

.introduction {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 200px 0 0 128px;
  align-items: flex-start;
  background-color: #fff;
  overflow: hidden;

  .image-wrap {
    position: relative;
    margin-right: 20px;
    margin-top: -30px;

    .screen1 {
      position: relative;
      width: 500px;
      z-index: 5;
    }
    .screen2 {
      position: absolute;
      top: -40px;
      left: -140px;
      width: 370px;
      z-index: 10;
    }

    .line {
      position: absolute;
      top: 50px;
      left: -220px;
      z-index: 20;
      width: 200px;
    }
  }

  .content-wrap {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .title {
      font-weight: 700;
      font-size: 40px;
      line-height: 49px;
      background: linear-gradient(180deg, #d96739 0%, #041539 70.96%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      margin-bottom: 30px;
    }

    .detail {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #000000;
      width: 70%;
      margin-bottom: 30px;
    }

    .btn-wrap {
      display: flex;
      flex-direction: row;

      img {
        width: 192px;
        margin-right: 27px;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
          transform: scale(0.95);
        }
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .introduction {
    padding: 150px 0 0 90px;

    .image-wrap {
      margin-right: 20px;
      margin-top: -20px;

      .screen1 {
        width: 400px;
      }
      .screen2 {
        top: -0px;
        left: -90px;
        width: 250px;
      }

      .line {
        top: 30px;
        left: -180px;
        width: 150px;
      }
    }

    .content-wrap {
      .title {
        font-size: 35px;
        line-height: 40px;
        margin-bottom: 20px;
      }

      .detail {
        font-size: 12px;
        line-height: 15px;
        width: 70%;
        margin-bottom: 20px;
      }

      .btn-wrap {
        img {
          width: 150px;
          margin-right: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .introduction {
    padding: 120px 0 0 30px;

    .image-wrap {
      margin-right: 20px;
      margin-top: -20px;

      .screen1 {
        width: 300px;
      }
      .screen2 {
        top: -10px;
        left: -70px;
        width: 200px;
      }

      .line {
        top: 30px;
        left: -130px;
        width: 100px;
      }
    }

    .content-wrap {
      .title {
        font-size: 28px;
        line-height: 40px;
        margin-bottom: 20px;
      }

      .detail {
        font-size: 10px;
        line-height: 15px;
        width: 70%;
        margin-bottom: 20px;
      }

      .btn-wrap {
        img {
          width: 120px;
          margin-right: 15px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .introduction {
    padding: 70px 0 0 20px;

    .image-wrap {
      margin-right: 0px;
      margin-top: 0px;
      margin-left: -20px;

      .screen1 {
        width: 200px;
      }
      .screen2 {
        top: 12px;
        left: -20px;
        width: 100px;
      }

      .line {
        top: 20px;
        left: -70px;
        width: 70px;
      }
    }

    .content-wrap {
      .title {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 20px;
      }

      .detail {
        font-size: 10px;
        line-height: 15px;
        width: 70%;
        margin-bottom: 20px;
      }

      .btn-wrap {
        img {
          width: 100px;
          margin-right: 15px;
        }
      }
    }
  }
}
