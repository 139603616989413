@import "../../../assets/css/common.scss";

.StatBenefit {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow: hidden;
  padding: 50px 150px;

  .stats1 {
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 22px;
    border: 3px dashed #000;
    background: #ffeee4;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 36px 135px;

    .extra {
      border-radius: 44px;
      background: #fafaff;
      box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.1);
      display: inline-flex;
      padding: 10px 17px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: flex-start;

      span {
        color: #389e35;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }
    }

    .title {
      color: #2b303a;
      font-size: 40px;
      font-weight: 700;
      width: 554px;
      margin-top: 6px;

      .line-through {
        color: #5d4fff;
      }
    }

    .detail {
      color: #000;
      font-size: 16px;
      font-weight: 500;
      width: 311px;
      margin-top: 24px;

      .line-through {
        font-weight: 700;
      }
    }

    .graph {
      position: absolute;
      top: 20px;
      right: 135px;
      display: flex;
      flex-direction: column;

      .cols {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;

        .col {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          margin: 0 10px;

          .title1 {
            color: #000;
            text-align: center;
            font-size: 40px;
            font-weight: 700;
          }
          .title2 {
            color: #d96739;
            text-align: center;
            font-size: 40px;
            font-weight: 700;
          }
          .rect1 {
            width: 125px;
            height: 31px;
            border-radius: 6px;
            border: 1px solid #000;
            background: #fff;
          }
          .rect2 {
            width: 125px;
            height: 169px;
            border-radius: 8px;
            border: 1px solid #000;
            background: #d96739;
          }
        }
      }

      .line {
        width: 455px;
        height: 11px;
        border-radius: 62px;
        background: #000;
      }
    }

    .close {
      width: 41px;
      height: 41px;
      position: absolute;
      top: 10px;
      right: 12px;
    }

    .circle1 {
      width: 250px;
      position: absolute;
      top: 0;
      right: 200px;
    }

    .circle2 {
      width: 150px;
      position: absolute;
      bottom: 0;
      left: 100px;
      transform: rotate(180deg);
    }

    .circle3 {
      width: 100px;
      position: absolute;
      top: 100px;
      right: -30px;
      transform: rotate(90deg);
    }
  }

  .stat-row-2 {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 25px;

    .stat2 {
      width: 420px;
      position: relative;
      height: 258px;
      border-radius: 25px;
      background: var(
        --gradient,
        linear-gradient(180deg, #d96739 0%, #041539 70.96%)
      );

      .star {
        position: absolute;
        top: -10px;
        left: -20px;
        width: 77px;
        height: 72px;
      }

      .bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        img {
          position: absolute;
          top: 50px;
          right: 50px;
          width: 200px;
        }
      }

      .content {
        position: absolute;
        bottom: 20px;
        left: 20px;

        .extra {
          display: flex;
          flex-direction: row;
          padding: 10px 17px;
          justify-content: center;
          align-items: center;
          border-radius: 44px;
          background: #fafaff;
          box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.1);
          z-index: 99;
          position: relative;

          img {
            width: 16px;
            height: 16px;
            margin-right: 5px;
          }

          span {
            color: #d96739;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
          }
        }

        .title {
          color: #f8f8f8;
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          font-size: 40px;
          font-weight: 700;
        }

        .logo {
          position: absolute;
          top: -45px;
          left: 20px;
        }
      }
    }

    .stat3 {
      flex: 1;
      border-radius: 25px;
      background: #ffeee4;
      margin-left: 20px;
      display: flex;
      align-items: center;
      position: relative;

      .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: flex-end;
        margin: 25px 33px;

        .extra {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 10px 17px;
          border-radius: 44px;
          background: #fafaff;
          box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.1);
          img {
            width: 24px;
            height: 24px;
            margin-right: 5px;
          }
          span {
            color: #797229;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
          }
        }

        .title1 {
          color: #000;
          font-size: 40px;
          font-weight: 700;
          .highlight {
            color: #5d4fff;
          }
        }

        .title2 {
          color: #dd4c1c;
          font-size: 40px;
          font-weight: 700;
        }
      }

      .avatar {
        height: 200px;
        position: absolute;
        right: 0;
        right: 33px;
      }

      .star {
        position: absolute;
        top: -10px;
        left: -20px;
        width: 77px;
        height: 72px;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .StatBenefit {
    padding: 40px 70px;

    .stats1 {
      border-radius: 22px;
      border: 3px dashed #000;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      padding: 30px 70px;

      .extra {
        border-radius: 44px;
        box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.1);
        padding: 8px 15px;

        img {
          width: 20px;
        }

        span {
          font-size: 14px;
          line-height: 24px;
        }
      }

      .title {
        font-size: 30px;
        width: 460px;
        margin-top: 6px;
      }

      .detail {
        font-size: 14px;
        width: 280px;
        margin-top: 24px;
      }

      .graph {
        top: 10px;
        right: 70px;

        .cols {
          margin-bottom: 10px;

          .col {
            margin: 0 10px;

            .title1 {
              font-size: 30px;
            }
            .title2 {
              font-size: 30px;
              font-weight: 700;
            }
            .rect1 {
              width: 100px;
              height: 30px;
              border-radius: 6px;
            }
            .rect2 {
              width: 100px;
              height: 160px;
              border-radius: 8px;
            }
          }
        }

        .line {
          width: 370px;
          height: 7px;
          border-radius: 62px;
        }
      }

      .close {
        width: 41px;
        height: 41px;
        top: 10px;
        right: 12px;
      }

      .circle1 {
        width: 250px;
        top: 0;
        right: 200px;
      }

      .circle2 {
        width: 150px;
        bottom: 0;
        left: 100px;
      }

      .circle3 {
        width: 100px;
        top: 100px;
        right: -30px;
      }
    }

    .stat-row-2 {
      width: 100%;
      margin-top: 25px;

      .stat2 {
        width: 350px;
        height: 258px;
        border-radius: 25px;

        .star {
          top: -10px;
          left: -20px;
          width: 77px;
          height: 72px;
        }

        .bg {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          img {
            top: 50px;
            right: 50px;
            width: 200px;
          }
        }

        .content {
          bottom: 20px;
          left: 20px;

          .extra {
            padding: 8px 10px;
            border-radius: 44px;
            box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.1);
            width: 250px;

            img {
              width: 14px;
              height: 14px;
              margin-right: 5px;
            }

            span {
              font-size: 14px;
              line-height: 24px;
            }
          }

          .title {
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-size: 30px;
          }

          .logo {
            top: -45px;
            left: 20px;
          }
        }
      }

      .stat3 {
        border-radius: 25px;
        margin-left: 20px;

        .content {
          margin: 25px 33px;

          .extra {
            padding: 10px 17px;
            border-radius: 44px;
            box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.1);
            img {
              width: 20px;
              height: 20px;
              margin-right: 5px;
            }
            span {
              font-size: 14px;
              line-height: 24px;
            }
          }

          .title1 {
            font-size: 30px;
          }

          .title2 {
            font-size: 30px;
          }
        }

        .avatar {
          height: 170px;
          right: 33px;
        }

        .star {
          top: -10px;
          left: -20px;
          width: 77px;
          height: 72px;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .StatBenefit {
    padding: 30px 30px;

    .stats1 {
      border-radius: 15px;
      border: 3px dashed #000;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      padding: 20px 30px;

      .extra {
        border-radius: 44px;
        box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.1);
        padding: 5px 15px;

        img {
          width: 15px;
        }

        span {
          font-size: 12px;
          line-height: 20px;
        }
      }

      .title {
        font-size: 24px;
        width: 370px;
        margin-top: 6px;
      }

      .detail {
        font-size: 12px;
        width: 50%;
        margin-top: 10px;
      }

      .graph {
        top: 10px;
        right: 20px;

        .cols {
          margin-bottom: 5px;

          .col {
            margin: 0 8px;

            .title1 {
              font-size: 24px;
            }
            .title2 {
              font-size: 24px;
              font-weight: 700;
            }
            .rect1 {
              width: 70px;
              height: 20px;
              border-radius: 5px;
            }
            .rect2 {
              width: 70px;
              height: 140px;
              border-radius: 5px;
            }
          }
        }

        .line {
          width: 270px;
          height: 5px;
          border-radius: 62px;
        }
      }

      .close {
        width: 20px;
        height: 20px;
        top: 10px;
        right: 12px;
      }

      .circle1 {
        width: 150px;
        top: 0;
        right: 100px;
      }

      .circle2 {
        width: 120px;
        bottom: 0;
        left: 100px;
      }

      .circle3 {
        width: 70px;
        top: 100px;
        right: -30px;
      }
    }

    .stat-row-2 {
      width: 100%;
      margin-top: 25px;

      .stat2 {
        width: 200px;
        height: 220px;
        border-radius: 20px;

        .star {
          top: -10px;
          left: -20px;
          width: 50px;
          height: 50px;
        }

        .bg {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          img {
            top: 90px;
            right: 20px;
            width: 100px;
          }
        }

        .content {
          bottom: 10px;
          left: 10px;

          .extra {
            padding: 5px 7px;
            border-radius: 44px;
            box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.1);
            width: 170px;

            img {
              width: 12px;
              height: 12px;
              margin-right: 2px;
            }

            span {
              font-size: 10px;
              line-height: 16px;
            }
          }

          .title {
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-size: 20px;
          }

          .logo {
            top: -35px;
            left: 20px;
            width: 60px;
          }
        }
      }

      .stat3 {
        border-radius: 20px;
        margin-left: 20px;

        .content {
          margin: 20px 20px;
          z-index: 99;

          .extra {
            padding: 5px 10px;
            border-radius: 44px;
            box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.1);
            img {
              width: 16px;
              height: 16px;
              margin-right: 5px;
            }
            span {
              font-size: 12px;
              line-height: 24px;
            }
          }

          .title1 {
            font-size: 24px;
          }

          .title2 {
            font-size: 24px;
          }
        }

        .avatar {
          height: 120px;
          right: 20px;
          top: 10px;
        }

        .star {
          top: -10px;
          left: -20px;
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .StatBenefit {
    padding: 30px 30px;

    .stats1 {
      border-radius: 10px;
      border: 3px dashed #000;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      padding: 20px 30px;
      padding-bottom: 200px;

      .extra {
        border-radius: 44px;
        box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.1);
        padding: 5px 15px;

        img {
          width: 15px;
        }

        span {
          font-size: 12px;
          line-height: 20px;
        }
      }

      .title {
        font-size: 20px;
        width: 100%;
        margin-top: 6px;
      }

      .detail {
        font-size: 12px;
        width: 100%;
        margin-top: 10px;
      }

      .graph {
        top: unset;
        right: 20px;
        bottom: 10px;

        .cols {
          margin-bottom: 5px;

          .col {
            margin: 0 8px;

            .title1 {
              font-size: 24px;
            }
            .title2 {
              font-size: 24px;
              font-weight: 700;
            }
            .rect1 {
              width: 70px;
              height: 20px;
              border-radius: 5px;
            }
            .rect2 {
              width: 70px;
              height: 140px;
              border-radius: 5px;
            }
          }
        }

        .line {
          width: 200px;
          height: 5px;
          border-radius: 62px;
        }
      }

      .close {
        width: 20px;
        height: 20px;
        top: 10px;
        right: 12px;
      }

      .circle1 {
        width: 150px;
        top: 0;
        right: 100px;
      }

      .circle2 {
        width: 120px;
        bottom: 0;
        left: 100px;
      }

      .circle3 {
        width: 70px;
        top: 100px;
        right: -30px;
      }
    }

    .stat-row-2 {
      width: 100%;
      margin-top: 25px;
      flex-direction: column;

      .stat2 {
        width: 100%;
        height: 220px;
        border-radius: 20px;

        .star {
          top: -10px;
          left: -20px;
          width: 50px;
          height: 50px;
        }

        .bg {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          img {
            top: 90px;
            right: 20px;
            width: 100px;
          }
        }

        .content {
          bottom: 10px;
          left: 10px;

          .extra {
            padding: 5px 7px;
            border-radius: 44px;
            box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.1);
            width: 170px;

            img {
              width: 12px;
              height: 12px;
              margin-right: 2px;
            }

            span {
              font-size: 10px;
              line-height: 16px;
            }
          }

          .title {
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-size: 20px;
          }

          .logo {
            top: -35px;
            left: 20px;
            width: 60px;
          }
        }
      }

      .stat3 {
        border-radius: 20px;
        margin-left: 0;
        margin-top: 20px;
        width: 100%;

        .content {
          margin: 20px 20px;
          z-index: 99;
          height: 220px;
          justify-content: flex-end;

          .extra {
            padding: 5px 10px;
            border-radius: 44px;
            box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.1);
            img {
              width: 16px;
              height: 16px;
              margin-right: 5px;
            }
            span {
              font-size: 12px;
              line-height: 24px;
            }
          }

          .title1 {
            font-size: 24px;
          }

          .title2 {
            font-size: 24px;
          }
        }

        .avatar {
          height: 120px;
          right: 20px;
          top: 10px;
        }

        .star {
          top: -10px;
          left: -20px;
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}
