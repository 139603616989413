@import "../../../assets/css/common.scss";

.AffiReason {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  overflow: hidden;
  padding: 100px 150px;

  .title {
    position: relative;
    img {
      width: 220px;
      position: absolute;
      top: -60px;
      left: 350px;
    }
    span {
      color: #2b303a;
      text-align: center;
      font-size: 40px;
      font-weight: 700;

      .highlight {
        border-radius: 9px;
        background: #2b303a;
        color: #fff;
        padding: 5px 10px;
      }
    }
  }

  .reason-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 90px;

    .reason1 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      flex: 2;
      height: 342px;
      box-sizing: border-box;
      border-radius: 27px;
      background: #f8f8f8;
      box-shadow: -5px 7px 15px 0px rgba(0, 0, 0, 0.1),
        -9px 8px 7px 0px #fff inset;
      margin-right: 25px;
      padding: 34px;
      position: relative;

      .extra {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 44px;
        background: #e2dfff;
        padding: 10px 17px;
        box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.1);

        img {
          margin-right: 5px;
          width: 24px;
          height: 24px;
        }

        span {
          color: #741fff;
          text-align: center;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        }
      }

      .title1 {
        color: #2b303a;
        font-size: 40px;
        font-weight: 700;
        margin-top: 18px;
      }

      .title2 {
        color: #6c6f05;
        font-size: 40px;
        font-weight: 700;
        margin-top: 10px;
        background-color: #f1e99b;
        border-radius: 3px;
        padding: 0 40px 0 10px;
      }

      .detail {
        color: #000;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        width: 311px;
        margin-top: 18px;
      }

      .sale {
        position: absolute;
        top: -30px;
        right: -30px;
      }
    }

    .reason2 {
      flex: 2;
      height: 342px;
      box-sizing: border-box;
      border-radius: 27px;
      background: #f8f8f8;
      box-shadow: -5px 7px 15px 0px rgba(0, 0, 0, 0.1),
        -9px 8px 7px 0px #fff inset;
      display: flex;
      flex-direction: column;
      padding: 32px;

      .extra {
        border-radius: 44px;
        background: #e2dfff;
        box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.1);
        padding: 10px 17px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: flex-start;

        img {
          width: 24px;
          height: 24px;
          margin-right: 5px;
        }
        span {
          color: #741fff;
          text-align: center;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        }
      }

      .main-row {
        display: flex;
        flex-direction: row;
        margin-top: 37px;
        flex: 2;

        .main1 {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex: 2;
          height: 177px;
          box-sizing: border-box;
          border-radius: 14px;
          background: #f8f8f8;
          box-shadow: -5px 7px 15px 0px rgba(0, 0, 0, 0.1),
            -9px 8px 7px 0px #fff inset;
          padding: 14px;

          .main1-row {
            display: flex;
            flex-direction: row;

            img {
              width: 48px;
              height: 48px;
              margin-right: 10px;
            }

            span {
              color: #434343;
              font-size: 20px;
              font-weight: 700;
              line-height: 24px;
            }
          }

          .logo {
            width: 89px;
            height: 89px;
            align-self: flex-end;
          }
        }

        .main2 {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex: 3;
          height: 177px;
          box-sizing: border-box;
          border-radius: 14px;
          border: 4px solid #d96739;
          background: #f8f8f8;
          box-shadow: -5px 7px 15px 0px rgba(0, 0, 0, 0.1),
            -9px 8px 7px 0px #fff inset;
          padding: 14px;
          margin-left: 20px;

          .main2-row {
            display: flex;
            flex-direction: row;
            img {
              width: 47px;
              height: 47px;
              margin-right: 5px;
            }
            .detail-col {
              display: flex;
              flex-direction: column;
              margin-left: 10px;
              span {
                color: #434343;
                font-size: 20px;
                font-weight: 700;
                line-height: 24px;
              }
              .detail-row {
                display: flex;
                flex-direction: row;
                .detail {
                  color: var(--purple, #5d4fff);
                  font-size: 10px;
                  font-style: italic;
                  font-weight: 400;
                  line-height: 24px;
                  margin-left: 5px;
                }
              }
            }
          }

          .main2-row2 {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .logo {
              width: 75px;
              height: 75px;
            }
            .plus {
              width: 40px;
              height: 40px;
            }
            .giff {
              width: 75px;
              height: 75px;
            }
          }
        }
      }
    }
  }

  .reason3 {
    margin-top: 25px;
    box-sizing: border-box;
    position: relative;
    width: 103%;
    height: 446px;
    display: flex;
    flex-direction: row;

    .card {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    .col1 {
      display: flex;
      flex-direction: column;
      flex: 1.5;
      z-index: 10;
      padding: 40px 50px;
      .extra {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 17px;
        border-radius: 44px;
        background: #e2dfff;
        box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.1);
        align-self: flex-start;

        img {
          width: 24px;
          height: 24px;
          margin-right: 5px;
        }
        span {
          color: #741fff;
          text-align: center;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        }
      }
      .title1 {
        color: #2b303a;
        font-size: 36px;
        font-weight: 700;
        margin-top: 25px;
      }
      .title2 {
        color: #ffffff;
        font-size: 36px;
        font-weight: 700;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background: var(
          --gradient,
          linear-gradient(180deg, #d96739 0%, #041539 70.96%)
        );
        padding: 3px 10px;
        align-self: flex-start;

        img {
          width: 45px;
          height: 45px;
        }
      }
      .detail1,
      .detail2 {
        color: #000;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-top: 19px;
        width: 400px;
      }
    }

    .col2 {
      flex: 1;
      display: flex;
      flex-direction: column;
      z-index: 10;
      padding: 40px 50px;
      .main2-row {
        display: flex;
        flex-direction: row;
        img {
          width: 47px;
          height: 47px;
          margin-right: 5px;
        }
        .detail-col {
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          span {
            color: #434343;
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
          }
          .detail-row {
            display: flex;
            flex-direction: row;
            .detail {
              color: var(--purple, #5d4fff);
              font-size: 10px;
              font-style: italic;
              font-weight: 400;
              line-height: 16px;
              margin-left: 5px;
            }
          }
        }
      }
      .package-ls {
        display: flex;
        flex-direction: column;
        padding: 10px;
        justify-content: center;
        align-items: center;
        border-radius: 27px;
        border: 3px dashed #5d4fff;
        margin-top: 13px;

        .package-detail {
          border-radius: 12px;
          background: #f8f8f8;
          /* Khối */
          box-shadow: -5px 7px 15px 0px rgba(0, 0, 0, 0.1),
            -9px 8px 7px 0px #fff inset;
          width: 100%;
          color: #434343;
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          text-align: center;
          padding: 8px 19px;
          box-sizing: border-box;
          margin-bottom: 12px;
        }

        .package-detail-1 {
          font-size: 24px;
          padding: 16px 19px;
          border-radius: 20px;
          border: 2px solid #d96739;
          background: #f8f8f8;
          box-shadow: -5px 7px 15px 0px rgba(0, 0, 0, 0.1),
            -9px 8px 7px 0px #fff inset;
        }

        .package-detail-2 {
          margin-bottom: 0;
          border-radius: 44px;
          background: #f8ffd0;
          box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.1);
          padding: 10px 17px;
          color: #8e7314;
          font-size: 10px;
          font-weight: 600;
          line-height: 17px;
        }
      }
    }

    .col3 {
      width: 200px;
      display: flex;
      flex-direction: column;
      z-index: 10;
      padding: 40px 40px 30px 40px;

      .empty {
        height: 120px;
      }

      .main1-row {
        display: flex;
        flex-direction: row;

        img {
          width: 48px;
          height: 48px;
          margin-right: 10px;
        }

        span {
          color: #434343;
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          width: 100px;
        }
      }

      .package-ls {
        border-radius: 27px;
        border: 3px dashed rgba(0, 0, 0, 0.3);
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 13px 12px;
        margin-top: 15px;

        .package-detail {
          width: 100%;
          border-radius: 20px;
          background: #f8f8f8;
          box-shadow: -5px 7px 15px 0px rgba(0, 0, 0, 0.1),
            -9px 8px 7px 0px #fff inset;
          color: #434343;
          text-align: center;
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          padding: 24px 24px;
          box-sizing: border-box;
          opacity: 0.699999988079071;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .anual {
          margin-top: 14px;
          flex: 1;
        }
      }
    }

    .btn {
      position: absolute;
      top: 15px;
      right: 0px;
      background-color: #d96739;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: 21px;
      box-shadow: 2px 4px 8px 0px #00000040;
      width: 260px;
      height: 69px;
      position: absolute;
      right: 0;
      bottom: 10px;
      cursor: pointer;
      user-select: none;
      z-index: 999;

      span {
        font-size: 24px;
        font-weight: 600;
        color: #fff;
      }

      img {
        width: 24px;
        height: 24px;
        margin-left: 5px;
      }

      &:hover {
        box-shadow: none;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .AffiReason {
    padding: 100px 70px;
    padding-bottom: 200px;

    .title {
      img {
        width: 170px;
        top: -60px;
        left: 280px;
      }
      span {
        font-size: 30px;
        font-weight: 700;

        .highlight {
          border-radius: 9px;
          padding: 5px 10px;
        }
      }
    }

    .reason-row {
      width: 100%;
      margin-top: 50px;

      .reason1 {
        flex: 2;
        height: 300px;
        border-radius: 25px;
        box-shadow: -5px 7px 15px 0px rgba(0, 0, 0, 0.1),
          -9px 8px 7px 0px #fff inset;
        margin-right: 20px;
        padding: 25px;

        .extra {
          padding: 5px 15px;
          box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.1);

          img {
            margin-right: 5px;
            width: 20px;
            height: 20px;
          }

          span {
            font-size: 14px;
            line-height: 24px;
          }
        }

        .title1 {
          font-size: 28px;
          margin-top: 18px;
        }

        .title2 {
          font-size: 28px;
          margin-top: 10px;
          border-radius: 3px;
          padding: 0 30px 0 10px;
        }

        .detail {
          font-size: 14px;
          line-height: 24px;
          width: 270px;
          margin-top: 10px;
        }

        .sale {
          top: -30px;
          right: -30px;
          width: 140px;
        }
      }

      .reason2 {
        flex: 2;
        height: 300px;
        border-radius: 25px;
        box-shadow: -5px 7px 15px 0px rgba(0, 0, 0, 0.1),
          -9px 8px 7px 0px #fff inset;
        padding: 25px;

        .extra {
          box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.1);
          padding: 8px 10px;

          img {
            width: 20px;
            height: 20px;
            margin-right: 2px;
          }
          span {
            font-size: 14px;
            line-height: 20px;
          }
        }

        .main-row {
          margin-top: 25px;
          flex: 2;

          .main1 {
            flex: 2;
            height: 177px;
            border-radius: 12px;
            box-shadow: -5px 7px 15px 0px rgba(0, 0, 0, 0.1),
              -9px 8px 7px 0px #fff inset;
            padding: 12px;

            .main1-row {
              img {
                width: 30px;
                height: 30px;
                margin-right: 5px;
              }

              span {
                font-size: 12px;
                line-height: 17px;
              }
            }

            .logo {
              width: 50px;
              height: 50px;
            }
          }

          .main2 {
            flex: 3;
            height: 177px;
            border-radius: 12px;
            border: 4px solid #d96739;
            box-shadow: -5px 7px 15px 0px rgba(0, 0, 0, 0.1),
              -9px 8px 7px 0px #fff inset;
            padding: 12px;
            margin-left: 10px;

            .main2-row {
              img {
                width: 30px;
                height: 30px;
                margin-right: 5px;
              }
              .detail-col {
                margin-left: 5px;
                span {
                  font-size: 12px;
                  line-height: 20px;
                }
                .detail-row {
                  .detail {
                    font-size: 7px;
                    line-height: 16px;
                    margin-left: 5px;
                  }
                }
              }
            }

            .main2-row2 {
              .logo {
                width: 50px;
                height: 50px;
              }
              .plus {
                width: 25px;
                height: 25px;
              }
              .giff {
                width: 50px;
                height: 50px;
              }
            }
          }
        }
      }
    }

    .reason3 {
      margin-top: 20px;
      width: 103%;
      height: 300px;

      .card {
        width: 100%;
        top: 0;
        left: 0;
      }

      .col1 {
        flex: 1.5;
        padding: 30px 30px;
        .extra {
          padding: 8px 20px;
          box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.1);

          img {
            width: 18px;
            height: 18px;
            margin-right: 3px;
          }
          span {
            font-size: 12px;
            line-height: 24px;
          }
        }
        .title1 {
          font-size: 24px;
          margin-top: 12px;
        }
        .title2 {
          font-size: 24px;
          border-radius: 10px;
          padding: 3px 10px;

          img {
            width: 36px;
            height: 36px;
          }
        }
        .detail1,
        .detail2 {
          font-size: 13px;
          line-height: 16px;
          margin-top: 13px;
          width: 100%;
        }
      }

      .col2 {
        flex: 1;
        padding: 20px 30px;
        .main2-row {
          img {
            width: 35px;
            height: 35px;
            margin-right: 5px;
          }
          .detail-col {
            margin-left: 10px;
            span {
              font-size: 16px;
              line-height: 20px;
            }
            .detail-row {
              .detail {
                font-size: 8px;
                line-height: 10px;
                margin-left: 5px;
              }
            }
          }
        }
        .package-ls {
          padding: 5px;
          border-radius: 20px;
          border: 3px dashed #5d4fff;
          margin-top: 10px;

          .package-detail {
            border-radius: 10px;
            box-shadow: -5px 7px 15px 0px rgba(0, 0, 0, 0.1),
              -9px 8px 7px 0px #fff inset;
            font-size: 14px;
            line-height: 16px;
            padding: 8px 10px;
            margin-bottom: 10px;
          }

          .package-detail-1 {
            font-size: 16px;
            padding: 10px 10px;
            border-radius: 10px;
            box-shadow: -5px 7px 15px 0px rgba(0, 0, 0, 0.1),
              -9px 8px 7px 0px #fff inset;
          }

          .package-detail-2 {
            box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.1);
            padding: 5px 10px;
            font-size: 8px;
            line-height: 14px;
          }
        }
      }

      .col3 {
        width: 160px;
        z-index: 10;
        padding: 20px 20px 10px 20px;

        .empty {
          height: 100px;
        }

        .main1-row {
          img {
            width: 36px;
            height: 36px;
            margin-right: 5px;
          }

          span {
            font-size: 16px;
            line-height: 20px;
            width: 80px;
          }
        }

        .package-ls {
          border-radius: 25px;
          border: 3px dashed rgba(0, 0, 0, 0.3);
          flex: 1;
          padding: 8px 10px;
          margin-top: 10px;

          .package-detail {
            width: 100%;
            border-radius: 16px;
            box-shadow: -5px 7px 15px 0px rgba(0, 0, 0, 0.1),
              -9px 8px 7px 0px #fff inset;
            font-size: 16px;
            line-height: 20px;
            padding: 8px 20px;
          }

          .anual {
            margin-top: 10px;
            flex: 1;
          }
        }
      }

      .btn {
        top: 15px;
        right: 0px;
        border-radius: 15px;
        box-shadow: 2px 4px 8px 0px #00000040;
        width: 160px;
        height: 40px;
        right: 0;
        bottom: 10px;

        span {
          font-size: 16px;
        }

        img {
          width: 20px;
          height: 20px;
          margin-left: 5px;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .AffiReason {
    padding: 100px 30px;
    padding-bottom: 150px;

    .title {
      img {
        width: 170px;
        top: -60px;
        left: 200px;
      }
      span {
        font-size: 24px;
        font-weight: 700;

        .highlight {
          border-radius: 9px;
          padding: 5px 10px;
        }
      }
    }

    .reason-row {
      width: 100%;
      margin-top: 50px;
      flex-direction: column;

      .reason1 {
        flex: 2;
        height: 300px;
        border-radius: 25px;
        box-shadow: -5px 7px 15px 0px rgba(0, 0, 0, 0.1),
          -9px 8px 7px 0px #fff inset;
        margin-right: 0px;
        padding: 25px;

        .extra {
          padding: 5px 15px;
          box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.1);

          img {
            margin-right: 5px;
            width: 20px;
            height: 20px;
          }

          span {
            font-size: 14px;
            line-height: 24px;
          }
        }

        .title1 {
          font-size: 28px;
          margin-top: 18px;
        }

        .title2 {
          font-size: 28px;
          margin-top: 10px;
          border-radius: 3px;
          padding: 0 30px 0 10px;
        }

        .detail {
          font-size: 14px;
          line-height: 24px;
          width: 270px;
          margin-top: 10px;
        }

        .sale {
          top: -30px;
          right: -30px;
          width: 140px;
        }
      }

      .reason2 {
        flex: 2;
        height: 300px;
        border-radius: 25px;
        box-shadow: -5px 7px 15px 0px rgba(0, 0, 0, 0.1),
          -9px 8px 7px 0px #fff inset;
        padding: 25px;
        margin-top: 20px;

        .extra {
          box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.1);
          padding: 8px 10px;

          img {
            width: 20px;
            height: 20px;
            margin-right: 2px;
          }
          span {
            font-size: 14px;
            line-height: 20px;
          }
        }

        .main-row {
          margin-top: 25px;
          flex: 2;

          .main1 {
            flex: 2;
            height: 177px;
            border-radius: 12px;
            box-shadow: -5px 7px 15px 0px rgba(0, 0, 0, 0.1),
              -9px 8px 7px 0px #fff inset;
            padding: 12px;

            .main1-row {
              img {
                width: 30px;
                height: 30px;
                margin-right: 5px;
              }

              span {
                font-size: 12px;
                line-height: 17px;
              }
            }

            .logo {
              width: 50px;
              height: 50px;
            }
          }

          .main2 {
            flex: 3;
            height: 177px;
            border-radius: 12px;
            border: 4px solid #d96739;
            box-shadow: -5px 7px 15px 0px rgba(0, 0, 0, 0.1),
              -9px 8px 7px 0px #fff inset;
            padding: 12px;
            margin-left: 10px;

            .main2-row {
              img {
                width: 30px;
                height: 30px;
                margin-right: 5px;
              }
              .detail-col {
                margin-left: 5px;
                span {
                  font-size: 12px;
                  line-height: 20px;
                }
                .detail-row {
                  .detail {
                    font-size: 7px;
                    line-height: 16px;
                    margin-left: 5px;
                  }
                }
              }
            }

            .main2-row2 {
              .logo {
                width: 50px;
                height: 50px;
              }
              .plus {
                width: 25px;
                height: 25px;
              }
              .giff {
                width: 50px;
                height: 50px;
              }
            }
          }
        }
      }
    }

    .reason3 {
      margin-top: 20px;
      width: 103%;
      height: 220px;

      .card {
        width: 100%;
        top: 0;
        left: 0;
      }

      .col1 {
        flex: 1;
        padding: 15px 15px;
        .extra {
          padding: 3px 7px;
          box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.1);

          img {
            width: 15px;
            height: 15px;
            margin-right: 3px;
          }
          span {
            font-size: 8px;
            line-height: 16px;
          }
        }
        .title1 {
          font-size: 16px;
          margin-top: 12px;
        }
        .title2 {
          font-size: 16px;
          border-radius: 10px;
          padding: 3px 7px;

          img {
            width: 24px;
            height: 24px;
          }
        }
        .detail1,
        .detail2 {
          font-size: 10px;
          line-height: 10px;
          margin-top: 13px;
          width: 100%;
        }
      }

      .col2 {
        flex: 1;
        padding: 15px 15px;
        .main2-row {
          img {
            width: 24px;
            height: 24px;
            margin-right: 0px;
          }
          .detail-col {
            margin-left: 10px;
            span {
              font-size: 12px;
              line-height: 16px;
            }
            .detail-row {
              .detail {
                font-size: 8px;
                line-height: 15px;
                margin-left: 5px;
              }
            }
          }
        }
        .package-ls {
          padding: 5px;
          border-radius: 12px;
          border: 2px dashed #5d4fff;
          margin-top: 5px;

          .package-detail {
            border-radius: 7px;
            box-shadow: -5px 7px 15px 0px rgba(0, 0, 0, 0.1),
              -9px 8px 7px 0px #fff inset;
            font-size: 10px;
            line-height: 16px;
            padding: 3px 8px;
            margin-bottom: 5px;
          }

          .package-detail-1 {
            font-size: 12px;
            padding: 5px 10px;
            border-radius: 10px;
            box-shadow: -5px 7px 15px 0px rgba(0, 0, 0, 0.1),
              -9px 8px 7px 0px #fff inset;
          }

          .package-detail-2 {
            box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.1);
            padding: 5px 8px;
            font-size: 8px;
            line-height: 10px;
          }
        }
      }

      .col3 {
        width: 110px;
        z-index: 10;
        padding: 10px 10px 12px 10px;

        .empty {
          height: 60px;
        }

        .main1-row {
          img {
            width: 24px;
            height: 24px;
            margin-right: 5px;
          }

          span {
            font-size: 12px;
            line-height: 16px;
            width: 70px;
          }
        }

        .package-ls {
          border-radius: 15px;
          border: 2px dashed rgba(0, 0, 0, 0.3);
          flex: 1;
          padding: 5px 5px;
          margin-top: 10px;

          .package-detail {
            width: 100%;
            border-radius: 10px;
            box-shadow: -5px 7px 15px 0px rgba(0, 0, 0, 0.1),
              -9px 8px 7px 0px #fff inset;
            font-size: 10px;
            line-height: 18px;
            padding: 8px 15px;
          }

          .anual {
            margin-top: 10px;
            flex: 1;
          }
        }
      }

      .btn {
        top: 5px;
        right: 0px;
        border-radius: 15px;
        box-shadow: 2px 4px 8px 0px #00000040;
        width: 120px;
        height: 35px;
        right: 0;
        bottom: 10px;

        span {
          font-size: 12px;
        }

        img {
          width: 15px;
          height: 15px;
          margin-left: 5px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .AffiReason {
    padding: 100px 30px;
    padding-bottom: 50px;

    .title {
      img {
        width: 130px;
        top: -50px;
        left: 100px;
      }
      span {
        font-size: 20px;
        font-weight: 700;

        .highlight {
          border-radius: 5px;
          padding: 3px 7px;
        }
      }
    }

    .reason-row {
      width: 100%;
      margin-top: 30px;
      flex-direction: column;

      .reason1 {
        flex: 2;
        height: 300px;
        border-radius: 25px;
        box-shadow: -5px 7px 15px 0px rgba(0, 0, 0, 0.1),
          -9px 8px 7px 0px #fff inset;
        margin-right: 0px;
        padding: 20px;

        .extra {
          padding: 5px 15px;
          box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.1);

          img {
            margin-right: 5px;
            width: 20px;
            height: 20px;
          }

          span {
            font-size: 14px;
            line-height: 24px;
          }
        }

        .title1 {
          font-size: 20px;
          margin-top: 18px;
        }

        .title2 {
          font-size: 20px;
          margin-top: 10px;
          border-radius: 3px;
          padding: 0 30px 0 10px;
        }

        .detail {
          font-size: 14px;
          line-height: 24px;
          width: 100%;
          margin-top: 10px;
        }

        .sale {
          top: -20px;
          right: -20px;
          width: 100px;
        }
      }

      .reason2 {
        flex: 2;
        height: 300px;
        border-radius: 25px;
        box-shadow: -5px 7px 15px 0px rgba(0, 0, 0, 0.1),
          -9px 8px 7px 0px #fff inset;
        padding: 20px;
        margin-top: 20px;

        .extra {
          box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.1);
          padding: 5px 8px;

          img {
            width: 16px;
            height: 16px;
            margin-right: 2px;
          }
          span {
            font-size: 10px;
            line-height: 16px;
          }
        }

        .main-row {
          margin-top: 25px;
          flex: 2;
          flex-direction: column;

          .main1 {
            flex: 2;
            height: 177px;
            border-radius: 12px;
            box-shadow: -5px 7px 15px 0px rgba(0, 0, 0, 0.1),
              -9px 8px 7px 0px #fff inset;
            padding: 12px;

            .main1-row {
              img {
                width: 30px;
                height: 30px;
                margin-right: 5px;
              }

              span {
                font-size: 12px;
                line-height: 17px;
              }
            }

            .logo {
              width: 50px;
              height: 50px;
            }
          }

          .main2 {
            flex: 3;
            height: 177px;
            border-radius: 12px;
            border: 4px solid #d96739;
            box-shadow: -5px 7px 15px 0px rgba(0, 0, 0, 0.1),
              -9px 8px 7px 0px #fff inset;
            padding: 12px;
            margin-left: 0;
            margin-top: 10px;

            .main2-row {
              img {
                width: 30px;
                height: 30px;
                margin-right: 5px;
              }
              .detail-col {
                margin-left: 5px;
                span {
                  font-size: 12px;
                  line-height: 20px;
                }
                .detail-row {
                  .detail {
                    font-size: 7px;
                    line-height: 16px;
                    margin-left: 5px;
                  }
                }
              }
            }

            .main2-row2 {
              .logo {
                width: 50px;
                height: 50px;
              }
              .plus {
                width: 25px;
                height: 25px;
              }
              .giff {
                width: 50px;
                height: 50px;
              }
            }
          }
        }
      }
    }

    .reason3 {
      margin-top: 20px;
      width: 103%;
      height: unset;
      flex-direction: column;
      box-sizing: border-box;
      border-radius: 27px;
      background: #f8f8f8;
      box-shadow: -5px 7px 15px 0px rgba(0, 0, 0, 0.1),
        -9px 8px 7px 0px #fff inset;

      .card {
        width: 100%;
        top: 0;
        left: 0;
        display: none;
      }

      .col1 {
        flex: 1;
        padding: 15px 15px;

        .extra {
          padding: 3px 7px;
          box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.1);

          img {
            width: 15px;
            height: 15px;
            margin-right: 3px;
          }
          span {
            font-size: 8px;
            line-height: 16px;
          }
        }
        .title1 {
          font-size: 16px;
          margin-top: 12px;
        }
        .title2 {
          font-size: 16px;
          border-radius: 10px;
          padding: 3px 7px;

          img {
            width: 24px;
            height: 24px;
          }
        }
        .detail1,
        .detail2 {
          font-size: 12px;
          line-height: 14px;
          margin-top: 13px;
          width: 100%;
        }
      }

      .col2 {
        flex: 1;
        padding: 15px 15px;
        .main2-row {
          img {
            width: 24px;
            height: 24px;
            margin-right: 0px;
          }
          .detail-col {
            margin-left: 10px;
            span {
              font-size: 12px;
              line-height: 16px;
            }
            .detail-row {
              .detail {
                font-size: 8px;
                line-height: 15px;
                margin-left: 5px;
              }
            }
          }
        }
        .package-ls {
          padding: 5px;
          border-radius: 12px;
          border: 2px dashed #5d4fff;
          margin-top: 5px;

          .package-detail {
            border-radius: 7px;
            box-shadow: -5px 7px 15px 0px rgba(0, 0, 0, 0.1),
              -9px 8px 7px 0px #fff inset;
            font-size: 10px;
            line-height: 16px;
            padding: 5px 8px;
            margin-bottom: 5px;
          }

          .package-detail-1 {
            font-size: 12px;
            padding: 5px 10px;
            border-radius: 10px;
            box-shadow: -5px 7px 15px 0px rgba(0, 0, 0, 0.1),
              -9px 8px 7px 0px #fff inset;
          }

          .package-detail-2 {
            box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.1);
            padding: 5px 8px;
            font-size: 8px;
            line-height: 10px;
          }
        }
      }

      .col3 {
        width: unset;
        z-index: 10;
        padding: 10px 10px 12px 10px;

        .empty {
          height: 0;
        }

        .main1-row {
          img {
            width: 24px;
            height: 24px;
            margin-right: 5px;
          }

          span {
            font-size: 12px;
            line-height: 16px;
            width: 70px;
          }
        }

        .package-ls {
          border-radius: 15px;
          border: 2px dashed rgba(0, 0, 0, 0.3);
          flex: 1;
          padding: 5px 5px;
          margin-top: 10px;

          .package-detail {
            width: 100%;
            border-radius: 10px;
            box-shadow: -5px 7px 15px 0px rgba(0, 0, 0, 0.1),
              -9px 8px 7px 0px #fff inset;
            font-size: 10px;
            line-height: 18px;
            padding: 8px 15px;
          }

          .anual {
            margin-top: 10px;
            flex: 1;
          }
        }
      }

      .btn {
        top: 5px;
        right: 0px;
        border-radius: 10px;
        box-shadow: 2px 4px 8px 0px #00000040;
        width: 90px;
        height: 30px;
        right: 0;
        bottom: 10px;

        span {
          font-size: 10px;
        }

        img {
          width: 12px;
          height: 12px;
          margin-left: 3px;
        }
      }
    }
  }
}
