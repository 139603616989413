@import "../../assets/css/common.scss";

.subscription {
  display: flex;
  flex-direction: column;
  padding: 100px 122px;

  .title {
    color: var(--dark-blue, #1b2a4a);
    font-size: 40px;
    font-weight: 700;
  }

  .action-wrap {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin-top: 20px;

    .select-wrap {
      display: flex;
      flex: 1;
      .package-select-wrap {
        display: flex;
        flex-direction: row;
        border-radius: 59px;
        border: 4px solid var(--purple, #5d4fff);
        padding: 5px;

        .package-select {
          text-align: center;
          font-size: 24px;
          font-family: Montserrat;
          font-weight: 700;
          padding: 15px 40px;
          color: var(--dark-blue, #1b2a4a);
          cursor: pointer;
          transition: all 0.3s ease;
          user-select: none;

          &.active {
            color: var(--gray-gray-50, #ececec);
            background-color: #5d4fff;
            border-radius: 59px;
          }
        }
      }
    }

    .money {
      width: 276px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 32px;
      border-radius: 10px;
      background: var(--purple, #5d4fff);
      position: relative;
      align-self: center;

      span {
        color: var(--gray-gray-50, #ececec);
        text-align: center;
        font-size: 32px;
        font-weight: 700;
      }

      &.disabled {
        background: var(--gray-gray-50, #ececec);
        span {
          color: var(--dark-blue, #1b2a4a);
        }
      }

      .chip {
        position: absolute;
        top: -10px;
        left: 20px;
        border-radius: 54px;
        background: var(--gray-gray-50, #ececec);
        box-shadow: 3px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 0px 23px;
        color: #000;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
      }
    }
  }

  .detail {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: 30px;

    .title2 {
      color: #000;
      text-align: center;
      font-size: 24px;
      font-weight: 700;

      align-self: stretch;
      text-align: left;
      padding: 18px 32px;
      border-radius: 50px;
      background: var(--gray-gray-50, #ececec);
      margin-bottom: 14px;
    }

    .detail-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: stretch;
      flex: 1;
      margin-bottom: 14px;

      .detail-title {
        color: var(--purple, #5d4fff);
        font-size: 20px;
        font-weight: 700;
        margin-left: 30px;
        display: flex;
        flex: 1;
      }

      .check {
        width: 276px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 48px;
        background: #c1efd1;
        margin-left: 32px;
      }

      .notcheck {
        width: 276px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 48px;
        background: #ffdada;
        margin-left: 32px;
      }

      .detail-text {
        width: 276px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 48px;
        background: #ececec;
        margin-left: 32px;

        span {
          color: var(--dark-blue, #1b2a4a);
          text-align: center;
          font-size: 20px;
          font-family: Montserrat;
          font-weight: 700;
        }
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .subscription {
    padding: 80px 70px;

    .title {
      font-size: 36px;
    }

    .action-wrap {
      margin-top: 20px;

      .select-wrap {
        .package-select-wrap {
          border-radius: 59px;
          border: 4px solid var(--purple, #5d4fff);
          padding: 5px;

          .package-select {
            font-size: 20px;
            padding: 15px 40px;

            &.active {
              border-radius: 59px;
            }
          }
        }
      }

      .money {
        width: 200px;
        height: 60px;
        margin-left: 32px;
        border-radius: 10px;

        span {
          font-size: 24px;
        }

        .chip {
          top: -10px;
          left: 20px;
          border-radius: 54px;
          box-shadow: 3px 4px 4px 0px rgba(0, 0, 0, 0.25);
          padding: 0px 23px;
          font-size: 14px;
        }
      }
    }

    .detail {
      margin-top: 30px;

      .title2 {
        font-size: 20px;
        padding: 18px 32px;
        border-radius: 50px;
        margin-bottom: 14px;
      }

      .detail-row {
        margin-bottom: 14px;

        .detail-title {
          font-size: 16px;
          margin-left: 30px;
        }

        .check {
          width: 200px;
          height: 56px;
          border-radius: 48px;
          margin-left: 32px;
        }

        .notcheck {
          width: 200px;
          height: 56px;
          border-radius: 48px;
          margin-left: 32px;
        }

        .detail-text {
          width: 200px;
          height: 56px;
          border-radius: 48px;
          margin-left: 32px;

          span {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .subscription {
    padding: 80px 40px;

    .title {
      font-size: 28px;
    }

    .action-wrap {
      margin-top: 20px;

      .select-wrap {
        .package-select-wrap {
          border-radius: 59px;
          border: 3px solid var(--purple, #5d4fff);
          padding: 4px;

          .package-select {
            font-size: 10px;
            padding: 10px 10px;

            &.active {
              border-radius: 59px;
            }
          }
        }
      }

      .money {
        width: 150px;
        height: 40px;
        margin-left: 15px;
        border-radius: 10px;

        span {
          font-size: 14px;
        }

        .chip {
          top: -7px;
          left: 10px;
          border-radius: 54px;
          box-shadow: 3px 4px 4px 0px rgba(0, 0, 0, 0.25);
          padding: 0px 15px;
          font-size: 8px;
        }
      }
    }

    .detail {
      margin-top: 30px;

      .title2 {
        font-size: 16px;
        padding: 15px 28px;
        border-radius: 50px;
        margin-bottom: 14px;
      }

      .detail-row {
        margin-bottom: 14px;

        .detail-title {
          font-size: 14px;
          margin-left: 20px;
        }

        .check {
          width: 150px;
          height: 45px;
          border-radius: 48px;
          margin-left: 15px;
        }

        .notcheck {
          width: 150px;
          height: 45px;
          border-radius: 48px;
          margin-left: 15px;
        }

        .detail-text {
          width: 150px;
          height: 45px;
          border-radius: 48px;
          margin-left: 15px;

          span {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .subscription {
    padding: 30px 20px 50px 20px;

    .title {
      font-size: 22px;
    }

    .action-wrap {
      margin-top: 15px;

      .select-wrap {
        .package-select-wrap {
          border-radius: 59px;
          border: 3px solid var(--purple, #5d4fff);
          padding: 4px;

          .package-select {
            font-size: 8px;
            padding: 10px 10px;

            &.active {
              border-radius: 59px;
            }
          }
        }
      }

      .money {
        width: 80px;
        height: 40px;
        margin-left: 10px;
        border-radius: 5px;

        span {
          font-size: 8px;
        }

        .chip {
          top: -6px;
          left: 4px;
          border-radius: 54px;
          box-shadow: 3px 4px 4px 0px rgba(0, 0, 0, 0.25);
          padding: 0px 10px;
          font-size: 6px;
        }
      }
    }

    .detail {
      margin-top: 15px;

      .title2 {
        font-size: 12px;
        padding: 10px 20px;
        border-radius: 50px;
        margin-bottom: 14px;
      }

      .detail-row {
        margin-bottom: 14px;

        .detail-title {
          font-size: 8px;
          margin-left: 10px;
        }

        .check {
          width: 80px;
          height: 20px;
          border-radius: 48px;
          margin-left: 15px;

          img {
            width: 10px;
          }
        }

        .notcheck {
          width: 80px;
          height: 20px;
          border-radius: 48px;
          margin-left: 15px;

          img {
            width: 10px;
          }
        }

        .detail-text {
          width: 80px;
          height: 20px;
          border-radius: 48px;
          margin-left: 15px;

          span {
            font-size: 8px;
          }
        }
      }
    }
  }
}
