$color-primary: #5c7d78;

$color-pink: #fbd0d0;
$color-green: #81b565;
$color-orange: #f9bf6e;
$color-white: #ffffff;
$color-black: #000000;
$color-light: #e8f9e9;
$color-dark: #25282b;

$color-gray: #333333;
$color-gray-300: #828282;
$color-gray-500: #f9faff;

.root-app {
  box-sizing: border-box;
  // margin: 0 auto;
  width: 100%;
  background-color: $color-white;
  // height: 100vh;
  // display: flex;
  overflow-y: scroll;
}

.flex-column {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.flex-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.underline {
  height: 1px;
  width: 92px;
  background-color: #f2f2f2;
  margin-top: 8px;
  margin-bottom: 8px;
}

.transition {
  transition: all 0.3s ease-in-out;
}

.color-white {
  color: $color-white;
}

.bg-white {
  background-color: $color-white;
}

.pointer {
  cursor: pointer;
  user-select: none;
}

.mr-12 {
  margin-right: 12px;
}

.mobile {
  display: none;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 20px;
  height: 20px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid $color-primary;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

@media screen and (max-width: 1200px) {
  .title {
    font-size: 30px;
  }
  .sub-title {
    font-size: 15px;
  }
}

@media screen and (max-width: 800px) {
  .title {
    font-size: 15px;
  }
  .sub-title {
    font-size: 8px;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  .btn-round-2 {
    border-radius: 20px;
    width: 70px;
    height: 20px;
    font-size: 8px;
  }
}

@media screen and (max-width: 600px) {
  .mobile {
    display: block;
  }
}
