@import "../../assets/css/common.scss";

.TermsOfService {
  padding: 24px;
}

@media screen and (max-width: 900px) {
}

@media screen and (max-width: 600px) {
}
