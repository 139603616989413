@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

html,
body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  height: 100%;
  background-color: #ffffff;
}

#root {
  height: 100%;
  display: flex;
  flex: 1;
}
