@import "../../assets/css/common.scss";

.introduction3 {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -100px;
  margin-bottom: 100px;
  padding-bottom: 100px;
  overflow: hidden;

  .image-wrap {
    position: relative;
    margin-right: 50px;

    .ball {
      width: 626px;
      height: 626px;
      transform: rotate(-90.081deg);
      flex-shrink: 0;
      border-radius: 626px;
      background: var(
        --gradient,
        linear-gradient(180deg, #d96739 0%, #041539 70.96%)
      );
    }
    .intro1 {
      position: absolute;
      top: 100px;
      left: -30px;
      width: 700px;
    }
  }

  .content-wrap {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10%;
    margin-right: 10%;

    .title {
      color: #041539;
      font-size: 45px;
      font-family: Montserrat;
      font-weight: 600;
      line-height: 120%;
      width: 400px;

      img {
        margin-left: 20px;
      }
    }

    .detail {
      border-radius: 12px;
      background: rgba(237, 237, 237, 0.6);
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25) inset;
      color: var(--dark-blue, #1b2a4a);
      font-size: 19px;
      font-family: Montserrat;
      font-weight: 500;
      padding: 24px 16px;
      margin-top: 38px;
    }
  }
}

@media screen and (max-width: 1400px) {
  .introduction3 {
    margin-top: -50px;
    margin-bottom: 0px;
    padding-bottom: 120px;

    .image-wrap {
      margin-right: 60px;

      .ball {
        width: 450px;
        height: 450px;
      }
      .intro1 {
        top: 80px;
        left: -40px;
        width: 550px;
      }
    }

    .content-wrap {
      margin-left: 7%;
      margin-right: 7%;

      .title {
        font-size: 36px;
        line-height: 120%;
        width: 300px;

        img {
          width: 30px;
          margin-left: 15px;
        }
      }

      .detail {
        border-radius: 12px;
        font-size: 15px;
        padding: 20px 14px;
        margin-top: 30px;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .introduction3 {
    margin-top: -50px;
    margin-bottom: 0px;
    padding-bottom: 120px;

    .image-wrap {
      margin-right: 30px;

      .ball {
        width: 280px;
        height: 280px;
      }
      .intro1 {
        top: 60px;
        left: -50px;
        width: 380px;
      }
    }

    .content-wrap {
      margin-left: 4%;
      margin-right: 4%;

      .title {
        font-size: 28px;
        line-height: 120%;
        width: 250px;

        img {
          width: 20px;
          margin-left: 10px;
        }
      }

      .detail {
        border-radius: 12px;
        font-size: 12px;
        padding: 10px 5px;
        margin-top: 20px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .introduction3 {
    margin-top: -80px;
    margin-bottom: 0px;
    padding-top: 10px;
    padding-bottom: 100px;
    position: relative;

    .image-wrap {
      margin-right: 0px;
      position: absolute;
      right: 40px;

      .ball {
        width: 150px;
        height: 150px;
      }
      .intro1 {
        top: 27px;
        left: -40px;
        width: 230px;
      }
    }

    .content-wrap {
      margin-left: 20px;
      margin-right: 30px;

      .title {
        font-size: 18px;
        line-height: 120%;
        width: 180px;

        img {
          width: 15px;
          margin-left: 5px;
        }
      }

      .detail {
        border-radius: 3px;
        font-size: 8px;
        padding: 5px 5px;
        margin-top: 15px;
        width: 140px;
      }
    }
  }
}
