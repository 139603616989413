@import "../../assets/css/common.scss";

.story {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .title1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: center;
    margin-left: -20%;
    margin-bottom: 80px;

    span {
      font-weight: 700;
      font-size: 48px;
      line-height: 72px;
      text-align: center;
      background: linear-gradient(180deg, #d96739 0%, #041539 70.96%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;

      .line-through {
        background: #4e4ff9;
        color: #ececec;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 5px;
        -webkit-text-fill-color: #ececec;
      }
    }
  }

  .story-content {
    background: linear-gradient(
      180deg,
      #ffffff 3.12%,
      #d96739 35.94%,
      #041539 70.96%
    );
    padding-bottom: 100px;

    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: row;

    .img-main {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 500px;
      margin-left: 10%;
      position: relative;

      img {
        width: 450px;
      }
      span {
        color: rgba(255, 255, 255, 0.6);
        text-align: center;
        font-size: 20px;
        font-style: italic;
        font-weight: 600;
        margin-top: 50px;
      }

      .bookmark {
        position: absolute;
        bottom: 180px;
        left: 410px;

        .story6 {
          width: 350px;
        }

        .notice {
          position: absolute;
          right: -130px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: #fff;
          padding: 20px 10px;
          border-radius: 15px;
          margin-top: 10px;

          img {
            width: 130px;
          }
          span {
            color: var(--dark-blue, #1b2a4a);
            text-align: center;
            font-size: 24px;
            font-weight: 500;
            line-height: 120%;
            margin-top: 20px;
          }
        }
      }
    }

    .story-1 {
      width: 370px;
    }

    .story-2 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: -70px;

      .story {
        width: 370px;
      }

      .line {
        width: 22px;
        margin-left: 70px;
        margin-top: 10px;
      }

      .note-wrap {
        margin-left: 70px;
      }

      span {
        color: #000;
        font-size: 16px;
        font-weight: 500;
        width: 300px;

        .line-through {
          background: #4e4ff9;
          color: #ececec;
          padding-left: 5px;
          padding-right: 5px;
          border-radius: 3px;
          -webkit-text-fill-color: #ececec;
        }
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .story {
    .title1 {
      margin-left: -20%;
      margin-bottom: 60px;

      span {
        font-size: 38px;
        line-height: 60px;

        .line-through {
          padding-left: 10px;
          padding-right: 10px;
          border-radius: 5px;
        }
      }
    }

    .story-content {
      padding-bottom: 100px;

      .img-main {
        width: 370px;
        margin-left: 10%;

        img {
          width: 330px;
        }
        span {
          font-size: 15px;
          margin-top: 30px;
        }

        .bookmark {
          bottom: 110px;
          left: 280px;

          .story6 {
            width: 350px;
          }

          .notice {
            right: -130px;
            padding: 15px 8px;
            border-radius: 15px;
            margin-top: 10px;

            img {
              width: 100px;
            }
            span {
              font-size: 20px;
              margin-top: 15px;
            }
          }
        }
      }

      .story-1 {
        width: 280px;
      }

      .story-2 {
        margin-top: -50px;

        .story {
          width: 280px;
        }

        .line {
          width: 22px;
          margin-left: 40px;
          margin-top: 10px;
        }

        .note-wrap {
          margin-left: 20px;
        }

        span {
          font-size: 14px;
          width: 220px;

          .line-through {
            padding-left: 5px;
            padding-right: 5px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .story {
    .title1 {
      margin-left: -20%;
      margin-bottom: 40px;

      span {
        font-size: 24px;
        line-height: 40px;

        .line-through {
          padding-left: 10px;
          padding-right: 10px;
          border-radius: 5px;
        }
      }
    }

    .story-content {
      padding-bottom: 100px;

      .img-main {
        width: 250px;
        margin-left: 10%;

        img {
          width: 230px;
        }
        span {
          font-size: 12px;
          margin-top: 20px;
        }

        .bookmark {
          bottom: 85px;
          left: 215px;

          .story6 {
            width: 250px;
          }

          .notice {
            right: -90px;
            padding: 10px 10px;
            border-radius: 15px;
            margin-top: 10px;

            img {
              width: 80px;
            }
            span {
              font-size: 14px;
              margin-top: 10px;
            }
          }
        }
      }

      .story-1 {
        width: 200px;
      }

      .story-2 {
        margin-top: -40px;

        .story {
          width: 200px;
        }

        .line {
          width: 15px;
          margin-left: 20px;
          margin-top: 10px;
        }

        .note-wrap {
          margin-left: 20px;
        }

        span {
          font-size: 10px;
          width: 140px;

          .line-through {
            padding-left: 5px;
            padding-right: 5px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .story {
    .title1 {
      margin-left: 0%;
      margin-bottom: 60px;

      span {
        font-size: 20px;
        line-height: 30px;

        .line-through {
          padding-left: 10px;
          padding-right: 10px;
          border-radius: 5px;
        }
      }
    }

    .story-content {
      padding-bottom: 100px;

      .img-main {
        width: 180px;
        margin-left: 10%;

        img {
          width: 130px;
        }
        span {
          font-size: 8px;
          margin-top: 10px;
        }

        .bookmark {
          bottom: 75px;
          left: 120px;

          .story6 {
            width: 100px;
          }

          .notice {
            right: -50px;
            padding: 5px 5px;
            border-radius: 5px;
            margin-top: 5px;

            img {
              width: 40px;
            }
            span {
              font-size: 8px;
              margin-top: 5px;
            }
          }
        }
      }

      .story-1 {
        width: 100px;
      }

      .story-2 {
        margin-top: -50px;

        .story {
          width: 100px;
        }

        .line {
          width: 12px;
          margin-left: 20px;
          margin-top: 10px;
        }

        .note-wrap {
          margin-left: 20px;
        }

        span {
          font-size: 7px;
          width: 70px;

          .line-through {
            padding-left: 5px;
            padding-right: 5px;
          }
        }
      }
    }
  }
}
