@import "../../assets/css/common.scss";

.introduction2 {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 1000px;
  margin-top: -50px;
  margin-bottom: 50px;
  overflow: hidden;

  .image-wrap {
    position: relative;

    .ball {
      width: 1000px;
      height: 1000px;
      flex-shrink: 0;
      border-radius: 1019px;
      background: var(
        --gradient,
        linear-gradient(180deg, #d96739 0%, #041539 70.96%)
      );
      position: absolute;
      top: 0;
      left: -500px;
    }

    .intro1 {
      z-index: 1;
      width: 270px;
      position: absolute;
      top: 50px;
      left: 130px;
    }

    .intro2 {
      z-index: 1;
      position: absolute;
      top: 0px;
      left: 420px;

      display: flex;
      flex-direction: column;

      .title {
        color: #000;
        font-size: 48px;
        font-family: Montserrat;
        font-weight: 700;
        line-height: 72px;
        margin-bottom: 40px;
        margin-left: 30px;
        width: 450px;
      }

      img {
        width: 270px;
      }

      .line {
        width: 100px;
        position: absolute;
        bottom: 80px;
        right: 140px;
        z-index: 20;
      }

      .detail {
        width: 380px;
        position: absolute;
        bottom: 0;
        right: -250px;
        color: #000;
        font-size: 24px;
        font-weight: 600;

        .line-through {
          border-radius: 3px;
          color: white;
          background: linear-gradient(180deg, #d96739 0%, #041539 70.96%);
        }
      }
    }

    .intro3 {
      z-index: 2;
      width: 250px;
      position: absolute;
      top: 110px;
      left: 340px;
    }
  }

  .content-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 380px;
    position: absolute;
    left: 900px;
    top: 30%;

    .detail {
      color: #000;
      font-size: 16px;
      font-family: Montserrat;
      font-weight: 500;
      margin-bottom: 20px;
    }
  }

  .stampt {
    width: 200px;
    position: absolute;
    right: -80px;
    top: 500px;
    opacity: 0.6000000238418579;
    filter: grayscale(1.2);
  }
}

@media screen and (max-width: 1400px) {
  .introduction2 {
    height: 700px;
    margin-top: -50px;
    margin-bottom: 50px;

    .image-wrap {
      .ball {
        width: 700px;
        height: 700px;
        top: 0;
        left: -350px;
      }

      .intro1 {
        width: 200px;
        top: 120px;
        left: 110px;
      }

      .intro2 {
        top: 0px;
        left: 320px;

        .title {
          font-size: 36px;
          line-height: 56px;
          margin-bottom: 40px;
          margin-left: 30px;
          width: 300px;
        }

        img {
          width: 200px;
        }

        .line {
          width: 80px;
          bottom: 60px;
          right: 70px;
        }

        .detail {
          width: 320px;
          right: -220px;
          font-size: 18px;

          .line-through {
            border-radius: 3px;
          }
        }
      }

      .intro3 {
        width: 180px;
        top: 110px;
        left: 270px;
      }
    }

    .content-wrap {
      width: 300px;
      left: 600px;
      top: 30%;

      .detail {
        font-size: 14px;
        margin-bottom: 20px;
      }
    }

    .stampt {
      width: 150px;
      right: -60px;
      top: 350px;
    }
  }
}

@media screen and (max-width: 900px) {
  .introduction2 {
    height: 550px;
    margin-top: -0px;
    margin-bottom: 50px;

    .image-wrap {
      .ball {
        width: 550px;
        height: 550px;
        top: 0;
        left: -300px;
      }

      .intro1 {
        width: 150px;
        top: 100px;
        left: 70px;
      }

      .intro2 {
        top: 0px;
        left: 230px;

        .title {
          font-size: 24px;
          line-height: 40px;
          margin-bottom: 40px;
          margin-left: 20px;
          width: 250px;
        }

        img {
          width: 150px;
        }

        .line {
          width: 60px;
          bottom: 50px;
          right: 80px;
        }

        .detail {
          width: 200px;
          bottom: -20px;
          right: -110px;
          font-size: 14px;

          .line-through {
            border-radius: 3px;
          }
        }
      }

      .intro3 {
        width: 150px;
        top: 80px;
        left: 180px;
      }
    }

    .content-wrap {
      width: 200px;
      left: 430px;
      top: 30%;

      .detail {
        font-size: 12px;
        margin-bottom: 10px;
      }
    }

    .stampt {
      width: 100px;
      right: -40px;
      top: 280px;
    }
  }
}

@media screen and (max-width: 600px) {
  .introduction2 {
    height: 400px;
    margin-top: 30px;
    margin-bottom: 50px;

    .image-wrap {
      .ball {
        width: 300px;
        height: 300px;
        top: 0;
        left: -150px;
      }

      .intro1 {
        width: 100px;
        top: 50px;
        left: 20px;
      }

      .intro2 {
        top: 20px;
        left: 130px;

        .title {
          font-size: 15px;
          line-height: 20px;
          margin-bottom: 15px;
          margin-left: 15px;
          width: 160px;
        }

        img {
          width: 100px;
        }

        .line {
          width: 30px;
          bottom: 20px;
          right: 60px;
        }

        .detail {
          width: 80px;
          bottom: -30px;
          right: -25px;
          font-size: 8px;

          .line-through {
            border-radius: 3px;
          }
        }
      }

      .intro3 {
        width: 100px;
        top: 50px;
        left: 100px;
      }
    }

    .content-wrap {
      width: 100px;
      left: 250px;
      top: 100px;

      .detail {
        font-size: 7px;
        margin-bottom: 10px;
      }
    }

    .stampt {
      width: 60px;
      right: -25px;
      top: 200px;
    }
  }
}
